<template>
  <el-card shadow="never">
    <el-form inline>
      <el-row>
        <el-col :span="24">
          <el-form-item label="技服条码：">
            <el-input ref="snCode" @change="snCodeChange" @keyup.enter.native="snCodeChange" v-model="snCode" placeholder="请输入技服条码" class="form-item" :disabled="disable.snCode"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="text-align: left">
      <el-button type="primary" size="mini" @click="startClick" :loading="startLoading" :disabled="disable.startBtn">开始扫码</el-button>
      <el-button type="primary" size="mini" @click="endClick" :loading="endLoading" :disabled="disable.endBtn">结束扫码</el-button>
      <el-button type="primary" size="mini" @click="expClick" :loading="expLoading" :disabled="disable.expBtn">导出记录</el-button>
    </div>
    <el-row style="padding-top: 30px;" class="row">
      <el-col :span="10">
        扫描开始时间：<span class="orange">{{ startTime }}</span>
      </el-col>
      <el-col :span="7">
        总计用时：<span class="orange">{{ totalSecondText }}</span>
      </el-col>
      <el-col :span="7">
        扫码次数：<span class="orange">{{ scanCnt }}</span>
      </el-col>
    </el-row>
    <el-row class="row">
      <el-col :span="10">
        扫描结束时间：<span class="orange">{{ endTime }}</span>
      </el-col>
      <el-col :span="7">
        平均用时：<span class="orange">{{ avgSecondText }}</span>
      </el-col>
    </el-row>

  </el-card>
</template>

<script>
export default {
  name: "OneSn",
  data() {
    return {
      id: '',
      snCode: '',
      totalSecond: 0,
      avgSecond: 0,
      scanCnt: 0,
      startTime: '',
      endTime: '',
      startLoading: false,
      endLoading: false,
      expLoading: false,
      loading: false,
      disable: {
        snCode: true,
        startBtn: false,
        expBtn: true,
        endBtn: true
      },

    }
  },
  computed: {
    avgSecondText() {
      if (this.avgSecond) {
        return this.avgSecond + '秒';
      } else {
        return ''
      }
    },
    totalSecondText() {
      if (this.totalSecond) {
        return this.totalSecond + '秒';
      } else {
        return ''
      }
    }
  },
  methods: {
    startClick() {
      this.startLoading = true;
      this.$axios.get('scanTime/oneStartScan').then(response => {
        this.startLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.id = res.data.id;
        this.startTime = res.data.startTime;

        this.disable.startBtn = true
        this.disable.endBtn = false;
        this.disable.expBtn = true
        this.disable.snCode = false

        setTimeout(() => {
          this.$refs.snCode.focus();
        }, 100)
      }, error => {
        this.startLoading = false;
        return this.$message.error('开始扫描失败，' + error.message);
      });
    },
    snCodeChange() {
      if (!this.snCode) {
        return
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$axios.post('scanTime/add', {
        scanId: this.id,
        snCode: this.snCode,
      }).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.totalSecond = res.data.totalSecond
        this.avgSecond = res.data.avgSecond
        this.startTime = res.data.startTime;
        this.endTime = res.data.endTime;
        this.scanCnt = res.data.scanCnt;

        this.snCode = ''
        this.$refs.snCode.focus()
      }, error => {
        this.loading = false;
        return this.$message.error('记录条码失败，' + error.message);
      });


      this.$refs.snCode.focus();
    },
    endClick() {
      this.endLoading = true;
      this.$axios.post('scanTime/endScan', this.id).then(response => {
        this.endLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.totalSecond = res.data.totalSecond
        this.avgSecond = res.data.avgSecond
        this.startTime = res.data.startTime;
        this.endTime = res.data.endTime;

        this.disable.startBtn = false
        this.disable.endBtn = true;
        this.disable.expBtn = false
        this.disable.snCode = true
      }, error => {
        this.endLoading = false;
        return this.$message.error('结束扫描失败，' + error.message);
      });

    },
    expClick() {
      this.expLoading = true;
      this.$axios.post('scanTime/exp', this.id).then(response => {
        this.expLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);

        }
      }, error => {
        this.expLoading = false;
        return this.$message.error('导出失败，' + error.message);
      });


      this.disable.startBtn = false
      this.disable.endBtn = true;
      this.disable.expBtn = false
    }
  }
}
</script>

<style scoped>
.form-item {
  width: 180px;
}

.row {
  font-size: 14px;
  color: #606266;
  line-height: 30px;
}

.orange {
  color: orange;
}
</style>