<template>
  <el-container>
    <el-header>
      <div class="title-box">
        <span class="title">联想SSCM系统扫描测试小程序</span>
      </div>
    </el-header>
    <el-main>
      <el-row :gutter="40">
        <el-col :span="12">
          <TableTitleComponent title="两个条码用时测试"/>
          <TwoSn/>
        </el-col>
        <el-col :span="12">
          <TableTitleComponent title="一个条码用时测试"/>
          <OneSn/>
        </el-col>
      </el-row>
    </el-main>
  </el-container>


</template>

<script>
import TableTitleComponent from "@/components/TableTitleComponent";
import TwoSn from "@/views/scantime/TwoSn";
import OneSn from "@/views/scantime/OneSn";

export default {
  name: "ScanTimeTest",
  components: {OneSn, TwoSn, TableTitleComponent},
  data() {
    return {
      twoData: {}
    }
  }
}
</script>

<style scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #373d41;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>